<!-- 专利和论著共用页面 -->
<template>
    <div class="treatise">
        <div class="treatise-list">
            <div
                class="treatise-item"
                v-for="(item, i) in list"
                :key="'it' + i"
            >
                <div
                    class="treatise-tag"
                    v-if="
                        tagIndex[
                            type.length == 1
                                ? getFullYear(item.patentApplicationTime)
                                : item.year
                        ] === getIndex(item)
                    "
                >
                    <!-- <div class="treatise-circle"></div> -->
                    <div class="treatise-num">
                        {{
                            type.length == 1
                                ? getFullYear(item.patentApplicationTime)
                                : item.year
                        }}
                    </div>

                    <!-- <div class="treatise-tag-line"></div> -->
                </div>

                <div class="word">{{ item.word.gbt }}</div>
            </div>
        </div>
        <Page
            style="margin-top:80px;margin-bottom:60px;"
            :total="total"
            :current="pageNum"
            :page-size="pageSize"
            @on-change="handleChange"
            show-total
        >
            <span class="ivu-page-total" style="font-size:16px;"
                >{{ "共有" | language }} {{ total }} {{ "条" | language }}</span
            >
        </Page>
    </div>
</template>

<script>
export default {
    props: ["type", "sort"],
    data() {
        return {
            data: null,
            list: null,
            total: 0,
            tagIndex: null,
            pageSize: 20,
            pageNum: 1
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.pageNum = 1;
            this.$Spin.show();
            let cond = {};
            // cond.type = { $in: [1, 2, 4] };
            cond.type = this.type;
            cond.lang = this.$store.state.lang;
            let res = await this.$http.post("/restful", {
                model: "Patents",
                cond,
                sort: this.sort,
                withCount: true
            });

            this.total = res.count;

            let obj = {};
            res.result.map(item => {
                let year = 0;
                if (this.type.length == 1) {
                    let a = new Date(item.patentApplicationTime);

                    year = a.getFullYear();
                } else {
                    year = item.year;
                }

                if (obj.hasOwnProperty(year)) {
                    return;
                } else {
                    if (this.type.length == 1) {
                        obj[year] = res.result.findIndex(
                            item =>
                                this.getFullYear(item.patentApplicationTime) ==
                                year
                        );
                    } else {
                        obj[year] = res.result.findIndex(
                            item => item.year == year
                        );
                    }
                }
            });
            this.data = res.result;
            this.tagIndex = obj;
            this.changeList();
            this.$Spin.hide();
        },
        handleChange(page) {
            this.pageNum = page;
            this.changeList();
        },
        changeList() {
            this.list = this.data.slice(
                (this.pageNum - 1) * this.pageSize,
                this.pageNum * this.pageSize
            );
        },
        getIndex(row) {
            return this.data.findIndex(item => item._id == row._id);
        },
        getFullYear(year) {
            let date = new Date(year);
            return date.getFullYear();
        }
    },
    watch: {
        $route() {
            this.fetchData();
        }
    }
};
</script>

<style lang="less" scoped>
.treatise {
    .treatise-list {
        // padding-top: 20px;

        min-height: 700px;

        .treatise-item {
            margin-bottom: 20px;
            .treatise-tag {
                margin-top: 40px;
                padding-left: 30px;
                display: flex;
                align-items: center;
                .treatise-circle {
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(0, 145, 255, 1);
                    border-radius: 50%;
                    margin-right: 20px;
                }

                .treatise-num {
                    margin-right: 10px;
                    font-size: 24px;
                    opacity: 0.9;
                    // color: #0091ff;
                    color: #000;
                    font-weight: bold;
                }

                .treatise-tag-line {
                    margin-left: 20px;
                    height: 1px;
                    width: 551px;
                    background-color: #008fff;
                }
            }
            &:first-child {
                margin-top: -40px;
            }
        }
        .word {
            margin-top: 40px;
            position: relative;
            text-align: left;
            padding-left: 30px;
            font-size: 18px;
            color: #303133;
            line-height: 26px;
        }
        .word:before {
            content: "\25C6";
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 26px;
            color: #000;
            opacity: 0.3;
            // color: rgba(0, 0, 0, 0.5);
            //   border-radius: 50%;
            //   background-color: #606266;
        }
    }
}
</style>
